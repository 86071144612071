import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import Cta from '../components/cta'
import Card from '../components/card'


const RegCompliance = ({ data, location }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
      <SEO
          title={post.frontmatter.meta.title}
          description={post.frontmatter.meta.description}
          url={data.site.siteMetadata.siteUrl + location.pathname}
          image={ data.site.siteMetadata.siteUrl + post?.frontmatter?.image?.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
        />

        <Hero
          class="intro-65"
          image={post?.frontmatter?.image?.childImageSharp?.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>


      {post.frontmatter.compliance != '' &&
        post.frontmatter.compliance != null && (
          <>
            {post.frontmatter.compliance.map((compliances, index) => (
              <section className="bg-white" key={index}>
                <MDBContainer>
                  <MDBRow>
                    <MDBCol md="12" className="pr-5 mb-5">
                      <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                        {compliances.title}
                      </h2>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    {compliances.compfeature.map((compfeatures, index) => (
                      <MDBCol
                        lg="4"
                        md="6"
                        className="pb-5 item-prods d-flex"
                        key={index}
                      >
                        <div className="card border-0 flex-fill">
                          <div className="card-body">
                            <div
                              style={{
                                minWidth: '66px',
                                maxWidth: '66px',
                              }}
                            >
                            <GatsbyImage
                              image={compfeatures.image.childImageSharp.gatsbyImageData}
                              alt={compfeatures.alttext}
                              className="mb-3"
                            />
                            </div>
                            <p className="text-medium text-blue-dark font-w-700">
                              {compfeatures.title}
                            </p>
                            <p className="text-medium text-gray-dark">
                              {compfeatures.description}
                            </p>
                          </div>
                          <div className="card-footer border-top-0">
                            <span className="text-medium-small pl-3">
                              <Link to={compfeatures.link} className="effect">
                                {compfeatures.linktext}
                              </Link>
                            </span>
                          </div>
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                </MDBContainer>
              </section>
            ))}
          </>
        )}

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <MDBContainer>
              <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                {' '}
                {post.frontmatter.helpfulresources.title}{' '}
              </h2>
              <p className="text-medium">
                {post.frontmatter.helpfulresources.subtitle}
              </p>
              {post.frontmatter.helpfulresources.helpfulres && (
                <MDBRow className="pt-5">
                  <>
                    {post.frontmatter.helpfulresources.helpfulres.map(
                      (helpfulres) => {
                        return (
                          <Card
                            collg="4"
                            colmd="6"
                            height="11.5rem"
                            title={helpfulres.title}
                            subtitle={helpfulres.subtitle}
                            description={helpfulres.description}
                            image={
                              helpfulres.image.childImageSharp.gatsbyImageData
                            }
                            alt={helpfulres.alttext}
                            placement={helpfulres.placement}
                            link={
                              helpfulres.link
                                ? helpfulres.link
                                : helpfulres.document.publicURL
                            }
                            titleclass="title-small"
                            descriptionClass="text-card-small py-2"
                          />
                        )
                      }
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </section>
        )}
        {post.frontmatter.cta && (
          <Cta
            ctatitle={post.frontmatter.cta.title}
            ctadescription={post.frontmatter.cta.description}
            ctalink={post.frontmatter.cta.link}
            ctalinktext={post.frontmatter.cta.linktext}
          />
        )}
    </Layout>
  )
}
export default RegCompliance

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }        
    markdownRemark(frontmatter: { name: { eq: "Regulatory compliance" } }) {
      frontmatter {
        meta {
          title
          description 
        }   
        title
        subtitle
        image {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        alttext
        ctatext
        ctaslug
        cta {
          title
          description
          linktext
          link
        }
        compliance {
          title
          compfeature {
            title
            description
            link
            linktext
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
          }
        }
        helpfulresources {
          title
          subtitle
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
      }
      html
    }
  }
`
